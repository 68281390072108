<template>
    <div v-if="authed" style="display:flex;height:100%;">
        <div style="flex:0 0 200px;border-right:4px solid #eee;">
			<v-data-table :headers="headers0" :items="quotations" item-key="name" dense
				:items-per-page="-1" fixed-header hide-default-footer
				show-select single-select v-model="selected"
				:loading="loading" loading-text="加载中...">
				<template v-slot:top>
					<div class="d-flex flex-wrap pa-2" style="gap:16px">
                        <v-btn color="primary" @click.stop="newTmpl">新建模板</v-btn>
					</div>
					<v-divider/>
				</template>
			</v-data-table>
			<v-divider/>
        </div>
        <div style="flex:1 1 auto;position:relative;">
			<div class="overflow-y-auto" style="position:absolute;top:0;left:0;right:0;bottom:0;">
				<v-data-table :headers="headers" :items="treatment" item-key="id" dense
					:items-per-page="-1" fixed-header :item-class="getItemClass">
					<template v-slot:top>
						<div class="d-flex flex-wrap pa-2" style="gap:16px">
							<v-btn color="primary" :disabled="selected.length === 0" @click.stop="showAddItem">添加条目</v-btn>
							<v-btn color="primary" :disabled="treatment.length === 0" @click.stop="save">保存模板</v-btn>
						</div>
						<v-divider/>
					</template>

					<template #body="props">
						<draggable v-model="treatment" tag="tbody" :clone="onCloneCallback">
							<data-table-row-handler	v-for="(item, index) in treatment" :key="index" :item="item" :headers="headers" :item-class="getItemClass">
								<template v-slot:item.actions="{ item }">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon v-bind="attrs" v-on="on" class="mr-1" size="20" @click.stop="showEditItem(item)">mdi-square-edit-outline</v-icon>
										</template>
										修改
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon v-bind="attrs" v-on="on" size="20" @click.stop="showCloneItem(item)">content_copy</v-icon>
										</template>
										复制
									</v-tooltip>
								</template>
							</data-table-row-handler>
						</draggable>
					</template>

				</v-data-table>
			</div>
		</div>
		<v-dialog v-model="dialog" persistent width="500">
			<v-card>
				<v-card-title>条目</v-card-title>
				<v-card-text>
					<v-form ref="form" class="px-4">
						<v-text-field label="ID" v-model.trim="qitem.id" :readonly="editmode" :rules="idRules"/>
						<v-text-field label="诊疗项目" v-model.trim="qitem.subject" :rules="textRules"/>
						<v-text-field label="计价说明" v-model.trim="qitem.description" :rules="textRules"/>
						<v-text-field label="表达式" v-model.trim="qitem.f" :rules="textRules"/>
					</v-form>
				</v-card-text>
				<v-divider/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" @click.stop="setItem">确定</v-btn>
					<v-btn color="primary" outlined @click.stop="dialog=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import DataTableRowHandler from "../components/DataTableRowHandler.vue";
	import draggable from "vuedraggable";

    export default {
		name: 'quotation',
		components: {
			draggable,
			DataTableRowHandler,
		},
		data() {
			return {
				authed: false,
				headers0: [
					{text:'名称',value:'name',width:160},
					{text:'',value:'_'},
				],
				headers: [
                    {text:'', value:'actions', width:80, sortable: false, cellClass:'pr-0'},
					{text:'ID', value:'id', width:100},
					{text:'诊疗项目', value:'subject', width:280},
					{text:'计价说明', value:'description', width:240},
					{text:'表达式', value:'f', width:360},
//					{text:'en', value:'enabled', width:80},
				],
				items: [],
				loading: false,
				quotations: [],
				selected: [],
				dialog: false,
				qitem: {},
				editmode: true,
				idRules: [v => !!v || '此项为必填'],
				textRules: [v => !!v || '此项为必填'],
			}
		},
		computed: {
			treatment: {
				get() {
					return this.selected[0]?.treatment || [];
				},
				set(v) {
					if (this.selected[0]) this.selected[0].treatment = v;
				},
			},
		},
		mounted() {
			this.authed = this.$hasPrivilege(['费用模板']);
			if (!this.authed) return;
			this.fetchData();
		},
		methods: {
			onCloneCallback(item) {
				const cloneMe = JSON.parse(JSON.stringify(item));
				return cloneMe;
			},			
			getItemClass(item) {
				return item.saved ? '' : 'cyan lighten-4';
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				this.loading = true;
				try {
					const res = await db.collection('wp3quotation').get();
					res.data.forEach(x => x.treatment.forEach(y => y.saved = true));
					this.quotations = res.data;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
            },
			async newTmpl() {
				const res = await this.$dialog.prompt({
					title: '新建模板',
					text: '模板名称',
					rules: [v => (!!v && !!v.trim()) || '请输入名称', v => this.quotations.findIndex(x => x.name === v) === -1 || '名称重复'],
				});
				if (res) {
					this.quotations.push({name:res, treatment:[]});
				}
			},
			showAddItem() {
				this.qitem = {};
				this.editmode = false;
				this.dialog = true;
				this.$nextTick(() => this.$refs.form.resetValidation());
			},
			showCloneItem(item) {
				this.qitem = {...item};
				this.qitem.id = null;
				this.qitem.saved = undefined;
				this.editmode = false;
				this.dialog = true;
				this.$nextTick(() => this.$refs.form.resetValidation());
			},
			showEditItem(item) {
				this.qitem = {...item};
				this.editmode = true;
				this.dialog = true;
				this.$nextTick(() => this.$refs.form.resetValidation());
			},
			setItem() {
				if (!this.$refs.form.validate()) return;
				try {
					eval(this.qitem.f);
				} catch(err) {
					this.$dialog.message.error('表达式无效');
					console.error(err);
					return;
				}
				let ct = this.selected[0].treatment
				if (this.editmode) {
					const index = ct.findIndex(x => x.id === this.qitem.id);
					this.$set(ct, index, this.qitem);
				} else if (ct.findIndex(x => x.id === this.qitem.id) > -1) {
					this.$dialog.message.error('ID重复');
					return;
				} else {
					ct.push(this.qitem);
				}
				this.dialog = false;
			},
			async save() {
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'saveQuotation',
						data: this.selected[0],
					});
					this.selected[0].treatment.forEach(y => y.saved = true);
					this.$dialog.message.success('保存完成');
				} catch(err) {
					this.$dialog.message.error('保存失败');
					console.error(err);
				}
				this.loading = false;
			},
        },
	}
</script>
